<template>
  <div
    class="component-loading"
    :style="{
      height: `${height}px`
    }"
  >
    <div>
      <div>
        <img
          src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1633544847158742325086.png"
          alt=""
        />
      </div>
      <div class="loading-text">{{ msg }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 400
    },
    msg: {
      type: String,
      default: '加载中...'
    }
  }
}
</script>

<style scoped lang="scss">
.component-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  .loading-text {
    color: #bfbfbf;
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
  }
}
</style>

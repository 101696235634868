<template>
  <div class="page-detail">
    <div class="back" @click="handleBack">
      <span>&lt; 返回</span>
    </div>

    <div class="info">
      <div class="header">
        <div class="title">{{ info.name }}</div>
        <div class="btn" @click="showScore = !showScore">
          <i class="iconfont icon-menu"></i>
          <span>{{ showScore ? '题目导航' : '查看成绩单' }}</span>
        </div>
      </div>

      <div class="content">
        <template v-if="!showScore">
          <div class="body">
            <Detail
              :info="info"
              :currentIndex="currentIndex"
              :onPrev="handlePrev"
              :onNext="handleNext"
            />
          </div>
          <Nav
            :info="info"
            :currentIndex="currentIndex"
            :onSelect="handleCurrentChange"
          />
        </template>
        <template v-else>
          <Score :examInfo="info" />
        </template>
      </div>
    </div>

    <Tip />
  </div>
</template>

<script>
import Nav from './nav'
import Detail from './detail'
import Tip from './tip'
import Score from './score'
import api from '@/api/exam'
import { TYPE_INFO } from '@/utils/type-list'

const sortFilterExam = (ids, list) => {
  return ids
    .reduce((arr, id) => {
      return arr.concat(list.filter((m) => m.cate_sub === id))
    }, [])
    .map((item) => ({
      ...item,
      cateInfo: TYPE_INFO[item.cate_sub]
    }))
}

export default {
  components: {
    Nav,
    Detail,
    Tip,
    Score
  },
  data() {
    return {
      info: {},
      currentIndex: 0,
      showScore: false
    }
  },
  created() {
    this.fetchInfo()
  },
  methods: {
    async fetchInfo() {
      const { examId } = this.$route.query
      const { data } = await api.examInfo({
        my_exam_id: examId
      })
      this.info = {
        ...(data || {}),
        exam_item: sortFilterExam(
          [
            36,
            37,
            38,
            39,
            40,
            45,
            46,
            42,
            43,
            44,
            52,
            53,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35
          ],
          data.exam_item || []
        )
      }
      this.currentIndex = 0
    },
    handleCurrentChange(index) {
      this.currentIndex = index
    },

    handlePrev() {
      const index = Math.max(0, this.currentIndex - 1)
      this.handleCurrentChange(index)
    },
    handleNext() {
      const index = Math.min(
        this.info.exam_item.length - 1,
        this.currentIndex + 1
      )
      this.handleCurrentChange(index)
    },

    handleBack() {
      // this.$router.go(-1)
      this.$router.replace({
        name: 'examList'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page-detail {
  .back {
    > span {
      color: #3b86ff;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .info {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 12px;
    .header {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 13px 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        color: #1f1f40;
        font-size: 20px;
        font-weight: bold;
      }
      .btn {
        padding: 8px 14px;
        border: 1px solid #bcbccb;
        border-radius: 5px;
        font-size: 14px;
        color: #1f1f40;
        display: flex;
        align-items: center;
        cursor: pointer;
        > i {
          font-size: 12px;
          margin-right: 4px;
          transform: scale(0.85);
          display: inline-block;
        }
        &:hover {
          background-color: #c3daff;
          border-color: #c3daff;
          color: #5696ff;
          > i {
            color: #5696ff;
          }
        }
      }
    }

    .content {
      display: flex;
      .body {
        flex: 1;
      }
    }
  }
}
</style>

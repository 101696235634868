<template>
  <div class="score-wrap">
    <template v-if="examInfo.type == 2">
      <div class="tip-wrap">
        <div class="content">
          <center>恭喜您已完成15分钟小测试的全部内容。</center>
          <center>本测试仅用于体验PTE题型，及作为课前基础测试的参考，不提供AI评分。</center>
          <center>如需老师进行测试分析，请扫码添加客服，免费获得哦~</center>
        </div>
        <center style="margin: 20px 0">
          <img :src="helperInfo.site_val" alt="" />
          <div class="tip-text">{{ helperInfo.remark }}</div>
        </center>
        <div>
          <center>您也可以点击右上角查看“题目导航”进行答案回看。</center>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="wrap" v-if="info.score_status == 1">
        <div class="left">
          <div class="user">
            <img class="avatar" :src="userInfo.headimgurl"></img>
            <div class="info">
              <div class="name">{{userInfo.name}}</div>
              <div>Test Date: <span>{{(info.created_at || '').split(' ')[0]}}</span></div>
              <div>Test Time: <span>{{(info.created_at || '').split(' ')[1]}}</span></div>
              <div>Phone: <span>{{userInfo.phone}}</span></div>
            </div>
            <div class="overall">
              <div class="overall-title">Overall Score</div>
              <div class="score">{{info.score_total || 0}}</div>
            </div>
          </div>

          <div class="type-wrap">
            <div class="title">Communicative Skills</div>
            <div class="circle-wrap">
              <block v-for="item of bigTypeScoreList" :key="item.name">
                <div class="score" :style="{borderColor: item.color}">{{item.score}}</div>
                <label>{{item.name}}</label>
              </block>
            </div>
            <div class="bar-wrap">
              <block v-for="item of bigTypeScoreList" :key="item.name">
                <div class="bar-label">{{item.name}}</div>
                <div class="bar-score">{{item.score}}</div>
                <div class="bar">
                  <div :style="{width: `${item.score / 90 * 100}%`}"/>
                </div>
              </block>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="type-wrap">
            <div class="title">
              Speaking Skills Breakdown
              <span>PTEGO专属评分</span>
            </div>
            <div class="bar-wrap">
              <block v-for="item of speakScoreList" :key="item.name">
                <div class="bar-label">{{item.name}}</div>
                <div class="bar-score">{{item.score}}</div>
                <div class="bar bar2">
                  <div :style="{width: `${item.score / 90 * 100}%`}"/>
                </div>
              </block>
            </div>
          </div>
          <div class="type-wrap" style="margin-top: 30px">
            <div class="title">Accuracy rate (%)<span>PTEGO专属评分</span></div>
            <div class="bar-wrap">
              <block v-for="item of rateSCoreList" :key="item.name">
                <div class="bar-label">{{item.name}}</div>
                <div class="bar-score">{{item.score}}%</div>
                <div class="bar bar2">
                  <div :style="{width: `${item.score}%`}"/>
                </div>
              </block>
            </div>
          </div>
        </div>
        <div class="tip" @click="handleShowHelp">
          <span>?</span>
        </div>
      </div>
      <template v-else>
        <Loading v-if="isLoading" />
        <Empty v-else class="empty" msg="正在评分中，请稍后再查看"/>
      </template>
    </template>

    <ScoreTip ref="tip"/>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import api from '@/api/exam'
  import Empty from '@/components/Empty'
  import Loading from '@/components/loading'
  import ScoreTip from './scoreTip'

  export default {
    components: {
      Empty,
      Loading,
      ScoreTip
    },
    props: {
      examInfo: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        info: {},
        isLoading: true,
      }
    },
    mounted() {
      this.fetchScore()
    },
    computed: {
      ...mapState('app', {
        userInfo: (state) => state.userInfo,
        helperInfo: (state) => state.helperInfo
      }),

      bigTypeScoreList() {
        return [
          {
            name: 'Listening',
            color: '#173057',
            score: this.info.listening || 0
          },
          {
            name: 'Reading',
            color: '#D0DA0D',
            score: this.info.reading || 0
          },
          {
            name: 'Speaking',
            color: '#595959',
            score: this.info.speaking || 0
          },
          {
            name: 'Writing',
            color: '#9D007D',
            score: this.info.writing || 0
          },
        ]
      },
      speakScoreList() {
        return [
          {
            name: 'RA',
            score: this.info.ra
          },
          {
            name: 'RS',
            score: this.info.rs
          },
          {
            name: 'DI',
            score: this.info.di
          },
          {
            name: 'RL',
            score: this.info.rl
          },
        ]
      },
      rateSCoreList() {
        return [
          {
            name: 'RWFIB',
            score: this.info.rwfib,
          },
          {
            name: 'RO',
            score: this.info.ro,
          },
          {
            name: 'RFIB',
            score: this.info.rfib,
          },
          {
            name: 'LFIB',
            score: this.info.fib,
          },
          {
            name: 'HIW',
            score: this.info.hiw,
          },
          {
            name: 'WFD',
            score: this.info.wfd,
          },
        ]
      }
    },
    methods: {
      async fetchScore() {
        this.isLoading = true
        const {examId} = this.$route.query
        const {data} = await api.examScore({
          my_exam_id: examId
        })
        this.info = data
        this.isLoading = false
      },
      handleShowHelp() {
        this.$refs.tip.show()
      }
    }
  }
</script>

<style scoped lang="scss">
  .score-wrap {
    flex: 1;

    .tip-wrap {
      padding: 70px 20px;
      font-size: 16px;
      font-weight: normal;
      img {
        width: 120px;
        height: 120px;
      }
      .tip-text {
        color: #9C9C9C;
        font-size: 16px;
      }

    }

    .wrap {
      display: flex;
      padding: 50px 0;
      position: relative;

      .left {
        padding: 0 70px;
        flex: 1;
        border-right: 1px solid #BFBFBF;

        .user {
          display: flex;

          .avatar {
            width: 110px;
            height: 110px;
            border-radius: 50%;
          }

          .info {
            flex: 1;
            padding: 0 20px;
            color: #1F1F40;
            font-size: 16px;
            font-weight: bold;

            .name {
              font-size: 18px;
              margin-bottom: 14px;
            }

            > div {
              margin-bottom: 10px;
            }

            span {
              font-weight: normal;
            }
          }

          .overall {
            .overall-title {
              background-color: #173057;
              padding: 10px 8px 4px 8px;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              color: #fff;
              font-size: 16px;
            }

            .score {
              background-color: #9d007d;
              height: 80px;
              text-align: center;
              line-height: 70px;
              font-size: 40px;
              font-weight: bold;
              color: #fff;
              border-bottom-left-radius: 60px;
              border-bottom-right-radius: 60px;
            }
          }
        }

        .type-wrap {
          margin-top: 50px;
        }
      }

      .right {
        padding: 0 70px;
        flex: 1;
      }

      .type-wrap {
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #1F1F40;
          padding: 10px 0;
          border-bottom: 1px solid #BFBFBF;
          > span {
            display: inline-block;
            margin-left: 14px;
            padding: 2px 8px;
            background-color: #FFC06A;
            border-radius: 5px;
            color: #fff;
            font-size: 14px;
          }
        }

        .circle-wrap {
          display: flex;
          padding: 20px 0 10px;
          justify-content: space-between;

          > block {
            .score {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              border: 4px solid #173057;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 22px;
              font-weight: bold;
              color: #1F1F40;
            }

            > label {
              display: inline-block;
              width: 100%;
              margin-top: 16px;
              font-size: 16px;
              color: #1F1F40;
              text-align: center;
            }
          }
        }

        .bar-wrap {
          margin-top: 10px;

          > block {
            display: flex;
            align-items: center;
            color: #1F1F40;
            font-size: 16px;
            margin-top: 10px;

            .bar-label {
              width: 100px;
            }

            .bar-score {
              width: 50px;
              font-weight: bold;
            }

            .bar {
              flex: 1;
              height: 28px;
              position: relative;

              &.bar2 {
                > div {
                  background-color: #9fa8da;
                }
              }

              > div {
                position: absolute;
                left: 0;
                top: 0;
                height: 28px;
                width: 70%;
                background-color: #3b86ff;
              }
            }
          }
        }
      }

      .tip {
        position: absolute;
        right: 18px;
        top: 20px;
        background-color: #bfbfbf;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: #3b86ff;
        }
      }
    }

    .empty {
      height: 300px;
      width: 100%;
    }
  }
</style>

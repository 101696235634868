<template>
  <div class="exam-content-swt">
    <div class="title">
      Read the passage below and summarize it using one sentence. Type your
      response in the box at the bottom of the screen. You have 10 minutes to
      finish this task. Your response will be judged on the quality of your
      writing and on how well your response presents the key points in the
      passage.
    </div>
    <div class="body">
      <div>
        <pre v-html="(detail.content_text || '').replace(/[\[\]]/g, '')"></pre>
      </div>
      <div class="ques-answer">
        <div>
          <label>答题记录</label><span>{{ examInfo.user_answer }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/exam'

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      content: ''
    }
  },
  methods: {
    submit() {
      this.handleSave()
      return true
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [this.content]
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-swt {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .user-answer {
    margin-top: 18px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #bfbfbf;
    color: #9c9c9c;
  }
  .ques-answer {
    padding: 16px;
    margin-top: 30px;
    background-color: #f6f6f6;
    border-radius: 10px;
    > div {
      display: flex;
      align-items: center;
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 68px;
        padding: 0 10px;
        height: 24px;
        background-color: #dcdcdc;
        border-radius: 20px;
        font-size: 16px;
        color: #9c9c9c;
      }
      > span {
        margin-left: 16px;
        color: #1f1f40;
        font-size: 18px;
      }
    }
  }
}
</style>

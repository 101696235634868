<template>
  <div class="exam-content-sst">
    <div class="title">
      You will hear a short report. Write a summary for a fellow student who was
      not present. You should write 50-70 words. You have 10 minutes to finish
      this task. Your response will be judged on the quality of your writing and
      on how well your response presents the key points presented in the
      lecture.
    </div>
    <div class="body">
      <div class="audio">
        <Audio :url="detail.audio.url" />
      </div>
      <div class="ques-answer">
        <div>
          <label>答题记录</label><span>{{ examInfo.user_answer }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/exam'
import Textarea from '../../components/textarea'
import Audio from '../../components/audio'

export default {
  components: {
    Textarea,
    Audio
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      content: ''
    }
  },
  methods: {
    submit() {
      this.handleSave()
      return true
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [this.content]
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-sst {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .ipt {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 14px;
    display: flex;
    justify-content: center;
  }
  .user-answer {
    margin-top: 18px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #bfbfbf;
    color: #9c9c9c;
  }
  .ques-answer {
    padding: 16px;
    margin-top: 30px;
    background-color: #f6f6f6;
    border-radius: 10px;
    > div {
      display: flex;
      align-items: center;
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 68px;
        padding: 0 10px;
        height: 24px;
        background-color: #dcdcdc;
        border-radius: 20px;
        font-size: 16px;
        color: #9c9c9c;
      }
      > span {
        margin-left: 16px;
        color: #1f1f40;
        font-size: 18px;
      }
    }
  }
}
</style>

<template>
  <div class="exam-content-ra">
    <div class="title">
      Look at the text below. In 40 seconds, you must read this text aloud as
      naturally and clearly as possible. You have 40 seconds to read aloud.
    </div>
    <div>
      <pre v-html="content"></pre>
    </div>
    <div class="ques-answer">
      <div>
        <label>答题记录:</label>
        <span><AudioMini :url="examInfo.user_answer"/></span>
      </div>
    </div>
  </div>
</template>

<script>
import AudioMini from '@/components/Audio/Audio.vue'
import api from '@/api/exam'

export default {
  components: { AudioMini },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    content() {
      return (this.detail.content_text || '')
        .replace(/\(/g, '')
        .replace(/\//g, '')
        .replace(/\s+/g, ' ')
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-ra {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  pre {
    margin-top: 10px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .ques-answer {
    padding: 16px;
    margin-top: 30px;
    background-color: #f6f6f6;
    border-radius: 10px;
    > div {
      display: flex;
      align-items: center;
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 68px;
        padding: 0 10px;
        height: 24px;
        background-color: #dcdcdc;
        border-radius: 20px;
        font-size: 16px;
        color: #9c9c9c;
      }
      > span {
        margin-left: 16px;
        color: #1f1f40;
        font-size: 18px;
        flex: 1;
      }
    }
  }
}
</style>

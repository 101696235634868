<template>
  <el-dialog
    :visible.sync="visible"
    title="成绩单咨询"
    width="430px"
    class="exam-detail-finish-dialog"
  >
    <div class="tip">
      对成绩单有疑问？扫码添加小助手微信咨询。PTEGO提供免费一对一模考分析，帮助你定位问题，制定学习方案，高效提分。
    </div>
    <div class="content">
      <img :src="helperInfo.site_val" alt="" />
      <div style="margin-top: 10px">{{ helperInfo.remark }}</div>
    </div>
    <div class="btn-wrap">
      <div class="btn" @click="visible = false">我知道了</div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState('app', {
      helperInfo: (state) => state.helperInfo
    })
  },
  methods: {
    show() {
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">
.exam-detail-finish-dialog {
  .tip {
    color: #1f1f40;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    img {
      width: 118px;
      height: 118px;
    }
    > div {
      font-size: 16px;
      line-height: 22px;
      color: #9c9c9c;
    }
  }
  .btn-wrap {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    border-radius: 5px;
    background-color: #3b86ff;
    padding: 10px 28px;
    color: #fff;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>

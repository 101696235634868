var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exam-com-radio"},_vm._l((_vm.list),function(item,index){return _c('el-radio',{key:`${index}_${item.text}`,class:[
      _vm.quesAnswer == index
        ? _vm.value == index
          ? 'success'
          : 'error'
        : _vm.value == index
        ? 'error'
        : ''
    ],attrs:{"disabled":"","label":index},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_vm._v(" "+_vm._s(_vm._f("indexChat")(index))+_vm._s(item.text)+" ")])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="exam-content-asq">
    <div class="title">
      You will hear a question. Please give a simple and short answer. Often
      just one or a few words is enough.
    </div>
    <div class="record-wrap">
      <div>
        <div class="audio">
          <Audio :url="detail.audio.url" :onInit="handleAudioInit" />
        </div>
      </div>
    </div>
    <div class="ques-answer">
      <div>
        <label>答题记录:</label>
        <span><AudioMini :url="examInfo.user_answer"/></span>
      </div>
    </div>
  </div>
</template>

<script>
import AudioMini from '@/components/Audio/Audio.vue'
import Audio from '../../components/audio'
import api from '@/api/exam'

export default {
  components: {
    Audio,
    AudioMini
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      recoredWaitTime: 0
    }
  },
  methods: {
    submit() {
      if (this.$refs.record && this.$refs.record.stop) {
        this.$refs.record.stop()
        return true
      }
    },

    handleAudioInit(audioTime, waitTime) {
      this.recoredWaitTime = Math.round(audioTime + waitTime + 30)
    },
    async handleUplaod(url) {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        oss_url: url
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-asq {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record-wrap {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 65px;
      width: 260px;
    }
    .audio {
      margin-top: 24px;
    }
  }
  .record {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .ques-answer {
    padding: 16px;
    margin-top: 30px;
    background-color: #f6f6f6;
    border-radius: 10px;
    > div {
      display: flex;
      align-items: center;
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 68px;
        padding: 0 10px;
        height: 24px;
        background-color: #dcdcdc;
        border-radius: 20px;
        font-size: 16px;
        color: #9c9c9c;
      }
      > span {
        margin-left: 16px;
        color: #1f1f40;
        font-size: 18px;
        flex: 1;
      }
    }
  }
}
</style>

import Audio from '@/components/Audio/Audio.vue'
import {
  apiWebAskQuestions,
  apiQuestionRecordList,
  apiGetclassify
} from '@/api/api.js'
import { mapActions } from 'vuex'

export default {
  components: {
    Audio
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String]
    },
    greId: {
      type: [Number, String]
    },
    isPractice: {
      type: Boolean
    },
    typeInfo: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      saveLoading: false,
      radio: '',
      list: [],
      content: '',
      currentId: null,
      textNum: 0,
      credit: 0
    }
  },
  created() {
    if (!this.isPractice) {
      return
    }
    this.getCredit()
    this.fetchList()
  },
  computed: {
    isLimit() {
      return this.textNum > 500
    },
    btnDisabled() {
      if (['RA', 'RS', 'DI', 'RL', 'ASQ'].includes(this.typeInfo.type)) {
        return this.isPractice
          ? !this.radio || !this.content.trim()
          : !this.content.trim()
      } else {
        return !this.content.trim()
      }
    },
    showAudio() {
      return (
        this.isPractice &&
        ['RA', 'RS', 'DI', 'RL', 'ASQ'].includes(this.typeInfo.type)
      )
    },

    i18n() {
      return this.$t('messages')
    },
  },
  methods: {
    ...mapActions('app', ['GetUserInfo']),
    getCredit() {
      apiGetclassify().then((res) => {
        if (res.code !== 200) {
          return
        }
        const type = res.data.find(
          (item) => item.id === this.typeInfo.classifyId
        ) || { child: [] }
        const practice = type.child.find(
          (item) => item.id === this.typeInfo.typeId
        )
        this.credit = practice.credit || 0
      })
    },
    descInput() {
      const reg = /(\w)+/gi
      const cReg = /[\u4e00-\u9fa5]/g
      const cVal = this.content.match(cReg) || []
      const val = this.content.match(reg) || []
      const txtVal = val.length
      const cTextVal = cVal.length
      this.textNum = txtVal + cTextVal
    },
    palyAudio(id) {
      this.currentId = id
    },
    handleClose() {
      this.$emit('closeDialog')
    },
    fetchList() {
      apiQuestionRecordList({
        question_id: this.id,
        page: 1,
        page_size: 4
      }).then((res) => {
        if (res.code != 200) {
          return
        }
        if (
          res.data.list &&
          res.data.list.length &&
          res.data.list[0].content_info.type === 'audio'
        ) {
          this.list = res.data.list
        }
      })
    },
    submit() {
      if (this.isLimit) {
        this.$layer.msg("<div class='error-icon'></div><div>字数过长</div>")
        return
      }
      this.saveLoading = true
      apiWebAskQuestions({
        qid: this.id,
        gre_id: this.greId,
        content: this.content,
        uqid: this.radio || 0
      }).then((res) => {
        this.saveLoading = false
        if (res.code != 200) {
          this.$layer.msg("<div class='error-icon'></div><div>提交失败了</div>")
          return
        }
        this.handleClose()
        this.$emit('successAsk')
        this.$layer.msg(
          "<div class='success-icon'></div><div>提问成功，老师会尽快回复!</div>"
        )

        this.GetUserInfo()
      })
    }
  }
}

<template>
  <div class="exam-content-mcm">
    <div class="body">
      <div>
        <pre v-html="detail.check.stem"></pre>
      </div>
      <div>
        <div class="title">
          Read the text and answer the question by selecting all the correct
          responses. More than one response is correct.
        </div>
        <div class="answer-title">{{ detail.check.title }}</div>
        <Checkbox
          :list="detail.check.options"
          class="check-wrap"
          :answer="examInfo.user_answer.split(',')"
          :quesAnswer="examInfo.question_answer.split('||')"
          :onInput="handleCheck"
        />
      </div>
    </div>
    <Score :info="examInfo" :detail="detail" />
  </div>
</template>

<script>
import Checkbox from '../../components/checkbox'
import Score from '../../components/score'
import api from '@/api/exam'

export default {
  components: {
    Checkbox,
    Score
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      checkList: []
    }
  },
  methods: {
    submit() {
      this.handleSave()
      return true
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [...this.checkList]
      })
      this.onFinish()
    },

    handleCheck(list) {
      this.checkList = list
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-mcm {
  color: #1f1f40;
  .body {
    display: flex;
    > div {
      flex: 1;
      margin-left: 50px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .answer-title {
    margin-top: 24px;
  }
  .check-wrap {
    margin-top: 10px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-detail-nav"},[_vm._m(0),_c('div',{staticClass:"body"},_vm._l((_vm.list),function(group,groupIndex){return _c('div',{key:group.group,staticClass:"block"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(group.group))]),_c('div',{staticClass:"list"},_vm._l((group.list),function(item,index){return _c('div',{key:item,staticClass:"tag success",class:{
            partfail: [36, 37, 38, 39, 40, 45, 46, 28].includes(
              item.cate_sub
            ),
            fail:
              (item.score_my != item.score_total || 0 == item.score_my) &&
              ![36, 37, 38, 39, 40, 45, 46, 28].includes(item.cate_sub),
            actived: _vm.selectId == item.id
          },on:{"click":function($event){return _vm.handleJump(groupIndex, index, item.id)}}},[_vm._v(" "+_vm._s(item.cateInfo.type)+" "+_vm._s(index + 1)+" ")])}),0)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"tag success"},[_vm._v("正确")]),_c('div',{staticClass:"tag fail"},[_vm._v("错误")]),_c('div',{staticClass:"tag partfail"},[_vm._v("非客观题或部分错误")])])
}]

export { render, staticRenderFns }
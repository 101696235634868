<template>
  <div
    class="component-btn"
    :class="{ active: check }"
    @click.stop="handleClick"
  >
    <div v-if="icon" class="icon">
      <i class="iconfont" :class="[icon]"></i>
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    check: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.component-btn {
  border: 1px solid #3b86ff;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  height: 28px;
  box-sizing: border-box;
  min-width: 90px;
  cursor: pointer;
  &.active {
    background-color: #c9e2ff;
    color: #5798ff;
  }
  .icon {
    background-color: #3b86ff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    > i {
      color: #fff;
      font-size: 16px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0 5px;
  }
}
</style>

<template>
  <div class="exam-content">
    <!--    {{ examInfo.cateInfo.type }}-->
    <components
      v-if="detail"
      :is="comName"
      :examInfo="examInfo"
      :detail="detail"
      :info="info"
    />
  </div>
</template>

<script>
import RA from './type/ra'
import RS from './type/rs'
import DI from './type/di'
import RL from './type/rl'
import ASQ from './type/asq'
import SWT from './type/swt'
import WE from './type/we'

import MCM from './type/mcm'
import MCS from './type/mcs'
import RO from './type/ro'
import RFIB from './type/rfib'
import WRFIB from './type/wrfib'

import SST from './type/sst'
import LMCM from './type/lmcm'
import LFIB from './type/lfib'
import HCS from './type/hcs'
import LMCS from './type/lmcs'
import SMW from './type/smw'
import HIW from './type/hiw'
import WFD from './type/wfd'

export default {
  components: {
    RA,
    RS,
    DI,
    RL,
    ASQ,
    SWT,
    WE,
    MCM,
    MCS,
    RO,
    RFIB,
    WRFIB,
    SST,
    LMCM,
    LFIB,
    HCS,
    LMCS,
    SMW,
    HIW,
    WFD
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    detail: {
      type: Function,
      default: () => ({})
    }
  },
  computed: {
    comName() {
      return {
        36: 'RA',
        37: 'RS',
        38: 'DI',
        39: 'RL',
        40: 'ASQ',
        45: 'SWT',
        46: 'WE',

        42: 'MCM',
        43: 'MCS',
        44: 'RO',
        52: 'RFIB',
        53: 'WRFIB',

        28: 'SST',
        29: 'LMCM',
        30: 'LFIB',
        31: 'HCS',
        32: 'LMCS',
        33: 'SMW',
        34: 'HIW',
        35: 'WFD'
      }[this.examInfo.cate_sub]
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content {
}
</style>

<template>
  <div class="exam-content-di">
    <div class="title">
      Look at the graph below. In 25 seconds, please speak into the microphone
      and describe in detail what the graph is showing. You will have 40 seconds
      to give your response.
    </div>
    <div class="record-wrap">
      <div class="record-img">
        <img :src="detail.img.url" alt="" />
      </div>
      <div class="ques-answer">
        <div>
          <label>答题记录:</label>
          <span><AudioMini :url="examInfo.user_answer"/></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioMini from '@/components/Audio/Audio.vue'
import api from '@/api/exam'

export default {
  components: {
    AudioMini
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-di {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record-wrap {
    margin-top: 24px;
    .record-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 260px;
    }
  }
  .ques-answer {
    padding: 16px;
    margin-top: 30px;
    background-color: #f6f6f6;
    border-radius: 10px;
    > div {
      display: flex;
      align-items: center;
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 68px;
        padding: 0 10px;
        height: 24px;
        background-color: #dcdcdc;
        border-radius: 20px;
        font-size: 16px;
        color: #9c9c9c;
      }
      > span {
        margin-left: 16px;
        color: #1f1f40;
        font-size: 18px;
        flex: 1;
      }
    }
  }
}
</style>

<template>
  <div class="exam-content-wrfib">
    <div class="title">
      In the text below some words are missing. Drag words from the box below to
      the appropriate place in the text. To undo an answer choice, drag the word
      back to the box below the text.
    </div>
    <div class="body">
      <div class="content">
        <span class="fill-content">
          <span
            v-for="(item, index) in textContentList"
            :key="index"
            :style="item.lang == 'default' ? 'margin:0' : ''"
          >
            <template v-if="item.text.indexOf('_') < 0">
              <span> {{ item.text }}</span>
              <span v-html="'&nbsp;'"></span>
              <br v-if="/[\n\r]/g.test(item.text)" />
            </template>
            <el-select
              v-model="greFillSelected[item.count]"
              v-if="item.choose && item.choose.length != 0 && !item.truth"
              placeholder=""
              popper-class="gre-fill-select"
              :class="[
                greFillSelected[item.count] === quesAnswerList[item.count]
                  ? 'green'
                  : 'red'
              ]"
            >
              <el-option
                v-for="(items, indexs) in item.choose"
                :key="indexs"
                :label="items.text"
                :value="items.text"
              ></el-option>
            </el-select>
            <span
              v-if="item.choose && item.choose.length != 0 && !item.truth"
              class="right-word"
              >{{ quesAnswerList[item.count] }}</span
            >
          </span>
        </span>
      </div>
    </div>
    <Score :info="examInfo" :detail="detail" />
  </div>
</template>

<script>
import api from '@/api/exam'
import { symbolsFix } from '@/utils/parctice'
import draggable from 'vuedraggable'
import Score from '../../components/score'
import { cloneDeep } from 'lodash'

export default {
  components: {
    draggable,
    Score
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      greFillSelected: []
    }
  },
  computed: {
    textContentList() {
      if (!this.detail.content_text) {
        return []
      }
      const content = symbolsFix(this.detail.content_text)

      const opts = content.match(/\[[^\]]+\]/g).map((str) =>
        str
          .replace(/[\[\]]/g, '')
          .split(',')
          .map((m) => m.trim())
      )
      const answerList = (this.answerDetail
        ? this.answerDetail.answer.article
        : []
      ).filter((m) => /_/g.test(m.text))
      let optCount = -1
      return content
        .replace(/\[[^\]]+\]/g, '_')
        .replace(/\r.?\n/g, '\n')
        .split(/[^\S\r\n]/g)
        .map((text) => {
          const isSelect = /_/g.test(text)
          if (isSelect) {
            optCount += 1
          }
          const answerData = answerList[optCount] || {}
          return {
            choose: (isSelect ? opts[optCount] : []).map((item) => ({
              text: item
            })),
            lang: 'en',
            text: text,
            ...(isSelect ? { count: optCount } : {}),
            ...(isSelect ? answerData : {})
          }
        })
    },
    quesAnswerList() {
      return this.examInfo.question_answer.split('||')
    }
  },
  watch: {
    textContentList() {
      this.textContentList.forEach((item) => {
        if (item.text === '_') {
          this.greFillSelected.push([])
        }
      })
    }
  },
  mounted() {
    this.greFillSelected = this.examInfo.user_answer.split(',')
  },
  methods: {
    submit() {
      this.handleSave()
      return true
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: this.greFillSelected
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-wrfib {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    margin: 20px auto;
    .content {
      & > span {
        line-height: 24px;
        display: inline-block;
        vertical-align: middle;
      }
      .fill-text {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 180px;
        padding-left: 10px;
        box-sizing: border-box;
        height: 20px;
        line-height: 16px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(112, 112, 112, 1);
        &:hover {
          .el-icon-circle-close {
            visibility: visible;
          }
        }
        &.green {
          color: #36c626;
        }
        &.red {
          color: #ff1a2e;
        }
        .el-icon-circle-close {
          position: absolute;
          visibility: hidden;
          right: 2px;
          top: 1px;
          cursor: pointer;
        }
      }
      .right-word {
        color: #36c626;
        margin-left: 6px;
      }
      ::v-deep .el-select {
        &.green {
          .el-input__inner {
            color: #36c626;
          }
        }
        &.red {
          .el-input__inner {
            color: #ff1a2e;
          }
        }
      }
      .face {
        color: #36c626;
        margin-left: 5px;
      }
    }
  }

  ::v-deep .el-input__inner {
    width: 180px;
    height: 22px;
    font-size: 14px;
    color: #1f1f40;
    line-height: 22px;
    border: 1px solid #707070;
    border-radius: 0;
  }
  ::v-deep .el-input__suffix-inner {
    margin-top: 4px;
    display: inline-block;
    .el-select__caret {
      font-size: 0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAAAXNSR0IArs4c6QAAAENJREFUCB1jYEADcnIODSCMJszAjCwAUsDIyFAPxA78/AqMHz8+OACThyuEKYJJoCsGK0RXhE0xIy5FMMUg+v9/hkYAy2gWfwBwB8UAAAAASUVORK5CYII=);
      background-repeat: no-repeat;
      height: 16px;
      background-position: center;
    }
  }
}
</style>

<template>
  <el-dialog
    :visible.sync="visible"
    title="免费模考分析"
    width="430px"
    class="exam-detail-finish-dialog"
  >
    <div class="tip">
      扫码添加小助手微信，可获得一次免费一对一模考分析。帮助你定位问题，制定学习方案，高效提分。
    </div>
    <div class="content">
      <img :src="helperInfo.site_val" alt="" />
      <div style="margin-top: 10px">{{ helperInfo.remark }}</div>
    </div>
    <div class="btn-wrap">
      <div class="btn" @click="visible = false">我知道了</div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { toDayZeroTime } from '@/filter/time'
export default {
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
      helperInfo: (state) => state.helperInfo
    })
  },
  watch: {
    userInfo() {
      const maxTime = 24 * 60 * 60 * 1000
      const nowTime = new Date().getTime()
      let localData = {
        time: toDayZeroTime()
      }
      try {
        localData = JSON.parse(localStorage.getItem('CAN_FIRST_FREE_GET')) || {
          time: toDayZeroTime()
        }
      } catch (e) {
        localData = {
          time: toDayZeroTime()
        }
      }

      const { can_first_free_get } = this.userInfo
      this.visible =
        can_first_free_get === 0 && nowTime - maxTime >= localData.time

      localStorage.setItem(
        'CAN_FIRST_FREE_GET',
        JSON.stringify({
          time: toDayZeroTime()
        })
      )
    }
  }
}
</script>

<style scoped lang="scss">
.exam-detail-finish-dialog {
  .tip {
    color: #1f1f40;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    img {
      width: 118px;
      height: 118px;
    }
    > div {
      font-size: 16px;
      line-height: 22px;
      color: #9c9c9c;
    }
  }
  .btn-wrap {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    border-radius: 5px;
    background-color: #3b86ff;
    padding: 10px 28px;
    color: #fff;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>

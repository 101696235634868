<template>
  <div class="exam-content-ro">
    <div class="wrap">
      <div class="title">
        The text boxes in the left panel have been placed in a random order.
        Restore the original order by dragging the text boxes from the left
        panel to the right panel.
      </div>
      <div class="body">
        <Drag
          :list="detail.check.items"
          :onInput="handleCheck"
          :answer="examInfo.user_answer.split(',')"
          :quesAnswer="examInfo.question_answer.split('||')"
        />
      </div>
    </div>

    <Score :info="examInfo" :detail="detail" />
  </div>
</template>

<script>
import Drag from '../../components/drag'
import Score from '../../components/score'
import api from '@/api/exam'

export default {
  components: {
    Drag,
    Score
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      checkList: []
    }
  },
  methods: {
    submit() {
      this.handleSave()
      return true
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [...this.checkList]
      })
      this.onFinish()
    },

    handleCheck(list) {
      this.checkList = list
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-ro {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    margin: 20px auto;
  }
}
</style>

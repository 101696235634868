<template>
  <div class="exam-content-hcs">
    <div class="title">
      You will hear a recording. Click on the paragraph that best relates to the
      recording.
    </div>
    <div class="body">
      <div class="audio">
        <Audio :url="detail.audio.url" />
      </div>
      <div class="ipt">
        <Radio :list="detail.check.options" :onInput="handleCheck" />
      </div>
    </div>

    <Score :info="examInfo" :detail="detail" />
  </div>
</template>

<script>
import api from '@/api/exam'
import Radio from '../../components/radio'
import Audio from '../../components/audio'
import Score from '../../components/score'

export default {
  components: {
    Radio,
    Audio,
    Score
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      checkList: []
    }
  },
  methods: {
    submit() {
      this.handleSave()
      return true
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [...this.checkList]
      })
      this.onFinish()
    },

    handleCheck(list) {
      this.checkList = list
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-hcs {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .ipt {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 14px;
    display: flex;
    justify-content: center;
  }
}
</style>

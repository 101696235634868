<template>
  <span v-html="html"></span>
</template>

<script>
import { diffWords, diffArrays } from 'diff'
import { diffWordsArray } from '@/utils/string'

export default {
  props: {
    answer: {
      type: String | Array,
      default: ''
    },
    content: {
      type: String | Array,
      default: ''
    },
    type: {
      type: String,
      default: 'word' // word, array, moreWord
    },
    splitStr: {
      type: String,
      default: ', '
    }
  },
  computed: {
    html() {
      if (this.type === 'word') {
        return diffWords(this.answer, this.content)
          .map((item) => {
            if (item.removed) {
              return `<i style="color: #FF606D;">(${item.value})</i>`
            }
            if (item.added) {
              return `<i style="color: #FF606D;text-decoration:line-through;">${item.value}</i>`
            }
            return `<i style="color: #36C626">${item.value}</i>`
          })
          .join('')
      } else if (this.type === 'array') {
        return diffArrays(this.answer, this.content)
          .map((item) => {
            if (item.removed) {
              return `<i style="color: #FF606D;">${item.value
                .map((m) => `(${m})`)
                .join(this.splitStr)}</i>`
            }
            if (item.added) {
              return `<i style="color: #FF606D;text-decoration:line-through;">${item.value.join(
                this.splitStr
              )}</i>`
            }
            return `<i style="color: #36C626">${item.value.join(
              this.splitStr
            )}</i>`
          })
          .join(this.splitStr)
      } else if (this.type === 'moreWord') {
        console.log(this.answer, this.content)
        return diffWordsArray(this.answer, this.content)
          .map((item) => {
            if (item.removed) {
              return `<i style="color: #FF606D;">(${item.value})</i>`
            }
            if (item.added) {
              return `<i style="color: #9C9C9C;">${item.value}</i>`
            }
            return `<i style="color: #36C626">${item.value}</i>`
          })
          .join(' ')
      }
    }
  }
}
</script>

<style scoped></style>

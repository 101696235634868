<template>
  <div class="exam-content-rl">
    <div class="title">
      You will hear a lecture. After listening to the lecture, in 10 seconds,
      please speak into the microphone and retell what you have just heard from
      the lecture in your own words. You will have 40 seconds to give your
      response.
    </div>
    <div class="record-wrap">
      <div v-if="detail.img">
        <img :src="detail.img.url" alt="" />
      </div>
      <div>
        <div class="audio">
          <Audio :url="detail.audio.url" :onInit="handleAudioInit" />
        </div>
      </div>
    </div>
    <div class="ques-answer">
      <div>
        <label>答题记录:</label>
        <span><AudioMini :url="examInfo.user_answer"/></span>
      </div>
    </div>
  </div>
</template>

<script>
import Record from '../../components/record'
import Audio from '../../components/audio'
import AudioMini from '@/components/Audio/Audio.vue'
import api from '@/api/exam'

export default {
  components: {
    Record,
    Audio,
    AudioMini
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      recoredWaitTime: 0
    }
  },
  methods: {
    handleAudioInit(audioTime, waitTime) {
      this.recoredWaitTime = Math.round(audioTime + waitTime + 30)
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-rl {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .record-wrap {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 65px;
      width: 260px;
    }
    .audio {
      margin-top: 24px;
    }
  }

  .record {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }

  .ques-answer {
    padding: 16px;
    margin-top: 30px;
    background-color: #f6f6f6;
    border-radius: 10px;
    > div {
      display: flex;
      align-items: center;
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
      > label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 68px;
        padding: 0 10px;
        height: 24px;
        background-color: #dcdcdc;
        border-radius: 20px;
        font-size: 16px;
        color: #9c9c9c;
      }
      > span {
        margin-left: 16px;
        color: #1f1f40;
        font-size: 18px;
        flex: 1;
      }
    }
  }
}
</style>

<template>
  <div class="wrap">
    <div>
      <label>得分</label>
      <div>{{ info.score_my }} / {{ info.score_total }}</div>
    </div>
    <div>
      <label>正确率</label>
      <div>{{ info.score_rate }}%</div>
    </div>
    <div>
      <label>解析</label>
      <div>{{ detail.gre_parse }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: 16px;
  margin-top: 30px;
  background-color: #f6f6f6;
  border-radius: 10px;
  > div {
    display: flex;
    align-items: center;
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }
    > label {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 68px;
      padding: 0 10px;
      height: 24px;
      background-color: #dcdcdc;
      border-radius: 20px;
      font-size: 16px;
      color: #9c9c9c;
    }
    > div {
      margin-left: 16px;
      color: #1f1f40;
      font-size: 18px;
      flex: 1;
    }
  }
}
</style>

<template>
  <div class="exam-content-lmcm">
    <div class="title">
      Listen to the recording and answer the question by selecting all the
      correct responses. You will need to select more than one response.
    </div>
    <div class="body">
      <div class="audio">
        <Audio :url="detail.audio.url" />
      </div>
      <div class="answer-title">
        Which of the fallowing factors are consistent with the theory of X-ray
        crystallography?
      </div>
      <Checkbox
        :list="detail.check.options"
        :answer="examInfo.user_answer.split(',')"
        :quesAnswer="examInfo.question_answer.split('||')"
        :onInput="handleCheck"
      />
    </div>

    <Score :info="examInfo" :detail="detail" />
  </div>
</template>

<script>
import api from '@/api/exam'
import Checkbox from '../../components/checkbox'
import Score from '../../components/score'
import Audio from '../../components/audio'

export default {
  components: {
    Checkbox,
    Audio,
    Score
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      checkList: []
    }
  },
  methods: {
    submit() {
      this.handleSave()
      return true
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [...this.checkList]
      })
      this.onFinish()
    },

    handleCheck(list) {
      this.checkList = list
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-lmcm {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .ipt {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 14px;
    display: flex;
    justify-content: center;
  }
  .answer-title {
    margin-top: 22px;
  }
}
</style>

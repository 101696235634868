var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exam-content-hiw"},[_c('div',{staticClass:"title"},[_vm._v(" You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker said. Please click on the words that are different. ")]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"audio"},[_c('Audio',{attrs:{"url":_vm.detail.audio.url}})],1),_c('div',{staticClass:"content"},_vm._l((_vm.disposeContent),function(item,index){return _c('span',{key:`${index}_${item.text}`,class:[
          { select: _vm.selectIndex.includes(index) },
          item.truth || _vm.selectIndex.includes(index)
            ? item.text === item.truth
              ? 'green'
              : 'red'
            : ''
        ],on:{"click":function($event){return _vm.handleSelect(index)}}},[_vm._v(" "+_vm._s(item.text)+" "),(item.truth)?_c('span',{staticClass:"right-word"},[_vm._v("("+_vm._s(item.truth)+")")]):_vm._e()])}),0)]),_c('Score',{attrs:{"info":_vm.examInfo,"detail":_vm.detail}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="word-diff-mark">
    <div class="right"><i /><span>正确</span></div>
    <div class="removed"><i /><span>(漏写)</span></div>
    <div class="added"><i /><span>不计分</span></div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.word-diff-mark {
  display: flex;
  align-items: center;
  > div {
    margin-left: 16px;
    font-size: 14px;
    > i {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 4px;
    }
  }
  .right {
    > span {
      color: #36c626 !important;
    }
    > i {
      background-color: #36c626;
    }
  }
  .removed {
    > span {
      color: #f18083 !important;
    }
    > i {
      background-color: #f18083;
    }
  }
  .added {
    > span {
      color: #9c9c9c !important;
    }
    > i {
      background-color: #9c9c9c;
    }
  }
}
</style>

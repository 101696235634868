<template>
  <div class="page-detail-nav">
    <div class="legend">
      <div class="tag success">正确</div>
      <div class="tag fail">错误</div>
      <div class="tag partfail">非客观题或部分错误</div>
    </div>

    <div class="body">
      <div class="block" v-for="(group, groupIndex) of list" :key="group.group">
        <div class="title">{{ group.group }}</div>
        <div class="list">
          <div
            class="tag success"
            :class="{
              partfail: [36, 37, 38, 39, 40, 45, 46, 28].includes(
                item.cate_sub
              ),
              fail:
                (item.score_my != item.score_total || 0 == item.score_my) &&
                ![36, 37, 38, 39, 40, 45, 46, 28].includes(item.cate_sub),
              actived: selectId == item.id
            }"
            v-for="(item, index) of group.list"
            :key="item"
            @click="handleJump(groupIndex, index, item.id)"
          >
            {{ item.cateInfo.type }}
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPE_INFO } from '@/utils/type-list'

const sortFilterExam = (ids, list) => {
  return ids
    .reduce((arr, id) => {
      return arr.concat(list.filter((m) => m.cate_sub === id))
    }, [])
    .map((item) => ({
      ...item,
      cateInfo: TYPE_INFO[item.cate_sub]
    }))
}

export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    onSelect: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectId: 0
    }
  },
  computed: {
    examList() {
      return this.info.exam_item || []
    },
    list() {
      return [
        {
          group: 'Speaking',
          list: sortFilterExam([36, 37, 38, 39, 40], this.examList)
        },
        {
          group: 'Writing',
          list: sortFilterExam([45, 46], this.examList)
        },
        {
          group: 'Reading',
          list: sortFilterExam([42, 43, 44, 52, 53], this.examList)
        },
        {
          group: 'Listening',
          list: sortFilterExam([28, 29, 30, 31, 32, 33, 34, 35], this.examList)
        }
      ]
    }
  },
  watch: {
    list: {
      deep: true,
      immediate: true,
      handler() {
        this.selectId = this.list[0]
          ? this.list[0].list[0]
            ? this.list[0].list[0].id
            : 0
          : 0
      }
    }
  },
  methods: {
    handleJump(groupIndex, index, id) {
      this.selectId = id
      const preLen = this.list
        .filter((m, i) => i < groupIndex)
        .reduce((list, m) => list.concat(m.list), []).length
      this.onSelect(preLen + index)
    }
  }
}
</script>

<style scoped lang="scss">
.page-detail-nav {
  margin: 16px 18px 16px 0px;
  width: 315px;
  min-width: 315px;
  background-color: #f6f6f6;
  border-radius: 5px;
  padding: 12px 20px;
  .legend {
    display: flex;
    flex-wrap: wrap;
  }
  .body {
    .block {
      margin-top: 18px;
      .title {
        font-size: 14px;
        color: #1f1f40;
        line-height: 1;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        margin: 4px -6px 0;
      }
    }
  }
  .tag {
    padding: 2px 9px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 3px;
    margin: 4px;
    border: 1px solid #f0f0f0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: translateY(-4px);
    }
    &.success {
      color: #36c626;
      border-color: #36c626;
      background-color: #d4fcf2;
      &.actived {
        background-color: #38c323;
        border-color: #38c323;
        color: #fff;
      }
    }
    &.fail {
      color: #ff606d;
      border-color: #ff606d;
      background-color: #ffdfe2;
      &.actived {
        background-color: #ec5460;
        border-color: #ec5460;
        color: #fff;
      }
    }
    &.partfail {
      color: #ff9800;
      border-color: #ff9800;
      background-color: #ffeacc;
      &.actived {
        background-color: #ed9023;
        border-color: #ed9023;
        color: #fff;
      }
    }
  }
}
</style>

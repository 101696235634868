import { render, staticRenderFns } from "./markWordMore.vue?vue&type=template&id=38917ac9&scoped=true"
import script from "./markWordMore.vue?vue&type=script&lang=js"
export * from "./markWordMore.vue?vue&type=script&lang=js"
import style0 from "./markWordMore.vue?vue&type=style&index=0&id=38917ac9&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38917ac9",
  null
  
)

export default component.exports
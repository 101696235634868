import AudioTool from '@/components/AudioTool/AudioTool.vue'
import { apiGetWebTeacherDetail } from '@/api/api.js'
import VueVideo from "@/components/vue-video.vue";
import { apiUserInfo } from '@/api/api.js'

export default {
  components: {
    AudioTool,
    VueVideo
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    greId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      localVisible: this.visible,
      teacherDetail: null,
      showDialog: true,
      isvip: null,
      loading: false
    }
  },
  created () {
    this.getUserInfo()
  },
  watch: {
    showDialog(val) {
      this.localVisible = val
    }
  },
  methods: {
    openVip() {
      this.$router.push({ name: 'vip' })
    },
    checkDetail() {
      apiGetWebTeacherDetail({
        ggid: this.greId
      }).then((res) => {
        if(res.code != 200){
          this.handleClose()
          return
        }
        if (!res.data || !res.data.detail || !res.data.detail.length) {
          this.handleClose()
          this.$layer.msg("暂无数据");
          return
        }
        const teacherDetail = {
          teacherName: null,
          teacherImg: null,
          text: null,
          video: null,
          audio: {
              url: null
          }
        }
        teacherDetail.teacherName = res.data.name;
        teacherDetail.teacherImg = res.data.img_url;
        for (let i = 0; i < res.data.detail.length; i++) {
          let type = res.data.detail[i].type;
          if (type == 'content') {
            teacherDetail.text = res.data.detail[i].content.text;
          }
          if (type == 'audio') {
            teacherDetail.audio.url = res.data.detail[i].content;
          }
          if (type == 'video') {
            teacherDetail.video = res.data.detail[i].content;
          }
        }
        this.teacherDetail = teacherDetail
      })
    },
    handleShowDialog(flag) {
      this.showDialog = flag
    },
    handleClose(){
      this.$emit('closeDialog')
    },
    getUserInfo() {
      this.loading = true
      apiUserInfo().then((res) => {
        this.isvip = res.data.vip.code == 1
        if(this.isvip) {
          this.checkDetail()
        } else {
          this.teacherDetail = {}
        }
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
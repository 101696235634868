<template>
  <div class="exam-detail">
    <div class="body">
      <Content :detail="detail" :info="info" :examInfo="examInfo" />
    </div>
    <div class="tool">
      <div class="left">
        <!--        <el-button type="primary" size="mini" @click="showAskDialog = true">-->
        <!--          <i class="iconfont icon-handup"></i>-->
        <!--          <span style="margin-left: 4px;">提问</span>-->
        <!--        </el-button>-->
        <!--        <Button-->
        <!--          v-if="detail.hasDetail"-->
        <!--          icon="icon-video"-->
        <!--          class="new-btn"-->
        <!--          @click="showVideoDialog = true"-->
        <!--        >-->
        <!--          视频讲解-->
        <!--        </Button>-->
        <el-button type="primary" class="origin-btn" @click="handleNavDetail"
          >显示原题</el-button
        >
      </div>
      <div class="right">
        <!--        <el-button type="primary" size="mini" @click="onPrev">上一题</el-button>-->
        <!--        <el-button type="primary" size="mini" @click="onNext">下一题</el-button>-->
      </div>
    </div>
    <!--    <AskQuestions-->
    <!--      v-if="showAskDialog"-->
    <!--      :isPractice="true"-->
    <!--      :typeInfo="examInfo.cateInfo"-->
    <!--      :visible="showAskDialog"-->
    <!--      :id="examInfo.gre_try_id"-->
    <!--      :greId="examInfo.gre_id"-->
    <!--      @closeDialog="showAskDialog = false"-->
    <!--    />-->
    <!--    <VideoDialog-->
    <!--      v-if="detail.hasDetail && showVideoDialog"-->
    <!--      :greId="examInfo.gre_id"-->
    <!--      :visible="showVideoDialog"-->
    <!--      :teacherDetail="detail.teacherDetail"-->
    <!--      @closeDialog="showVideoDialog = false"-->
    <!--    />-->
  </div>
</template>

<script>
import { apiGetWebQuestion } from '@/api/api.js'
import Content from './content'
import Button from '@/components/button'
import AskQuestions from '@/components/ExercisesTabs/components/ask-questions/ask-questions.vue'
import VideoDialog from '@/components/VideoDialog/VideoDialog.vue'

export default {
  components: {
    Content,
    AskQuestions,
    Button,
    VideoDialog
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    currentIndex: {
      type: Number,
      default: 0
    },

    onPrev: {
      type: Function,
      default: () => {}
    },
    onNext: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      detail: null,
      showAskDialog: false,
      showVideoDialog: false
    }
  },
  watch: {
    examInfo: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchDetail()
      }
    }
  },
  computed: {
    examInfo() {
      return (this.info.exam_item || [])[this.currentIndex] || {}
    }
  },
  methods: {
    fetchDetail() {
      if (!this.examInfo || !this.examInfo.gre_try_id) {
        return false
      }

      this.detail = null
      apiGetWebQuestion({
        id: this.examInfo.gre_try_id
      }).then((res) => {
        this.detail = res.data
      })
    },

    handleNavDetail() {
      window.open(
        this.$router.resolve({
          name: 'Exercises',
          query: {
            clid: this.detail.cate_info.cate_id,
            practiceId: this.detail.id,
            from: 'search'
          }
        }).href,
        '_blank'
      )
    }
  }
}
</script>

<style scoped lang="scss">
.exam-detail {
  padding: 0 18px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .body {
    flex: 1;
  }
  .origin-btn {
    background-color: #ffc06a !important;
    border-color: #ffc06a !important;
    height: 30px;
  }
  .tool {
    display: flex;
    align-items: center;
    padding: 16px 0;
    .left {
      flex: 1;
      display: flex;
      align-items: center;
      > div,
      button {
        margin-right: 10px;
      }
    }
    ::v-deep .el-button {
      background-color: #3b86ff;
      border-color: #3b86ff;
    }
  }
  ::v-deep .el-button {
    height: 28px;
    padding: 4px 15px;
  }
}
</style>

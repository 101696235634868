var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-checkbox-group',{staticClass:"exam-com-checkbox",attrs:{"disabled":""},model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},_vm._l((_vm.list),function(item,index){return _c('el-checkbox',{key:`${index}_${item.text}`,class:[
      _vm.quesAnswerMap[index]
        ? _vm.checkList.includes(index)
          ? 'success'
          : ''
        : 'error'
    ],attrs:{"label":index}},[_vm._v(" "+_vm._s(_vm._f("indexChat")(index))+_vm._s(item.text)+" ")])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="exam-content-wfd">
    <div class="title">
      You will hear a sentence. Type the sentence in the box below exactly as
      you hear it. Write as much of the sentence as you can. You will hear the
      sentence only once.
    </div>
    <div class="body">
      <div class="audio">
        <Audio :url="detail.audio.url" />
      </div>
      <div class="user-answer">
        <WordDiff
          type="moreWord"
          :answer="detail.question_answer"
          :content="examInfo.user_answer"
        />
        <div class="answer-footer">
          <div>Word count: {{ examInfo.user_answer.length }}</div>
          <div>
            <WordMark />
          </div>
        </div>
      </div>
    </div>
    <Score :info="examInfo" :detail="detail" />
  </div>
</template>

<script>
import api from '@/api/exam'
import Textarea from '../../components/textarea'
import Audio from '../../components/audio'
import Score from '../../components/score'
import WordDiff from '@/components/wordDiff/index'
import WordMark from '@/components/wordDiff/markWordMore'

export default {
  components: {
    Textarea,
    Audio,
    Score,
    WordDiff,
    WordMark
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    examInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      content: ''
    }
  },
  methods: {
    submit() {
      this.handleSave()
      return true
    },
    async handleSave() {
      await api.submit({
        my_exam_id: this.examInfo.my_exam_id,
        qid: this.itemData.gre_try_id,
        contents: [this.content]
      })
      this.onFinish()
    }
  }
}
</script>

<style scoped lang="scss">
.exam-content-wfd {
  color: #1f1f40;
  .title {
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    margin: 0 auto;
  }
  pre {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    word-break: break-word;
    word-spacing: normal;
    white-space: pre-wrap;
  }
  .ipt {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
  .audio {
    margin-top: 14px;
    display: flex;
    justify-content: center;
  }
  .user-answer {
    margin-top: 18px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #bfbfbf;
    color: #9c9c9c;
    .answer-footer {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>

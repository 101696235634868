var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exam-content-rfib"},[_c('div',{staticClass:"title"},[_vm._v(" Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank. ")]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"content"},[_vm._l((_vm.textContentList),function(item,index){return [_c('span',{key:index},[(item.text.indexOf('_') < 0)?[_c('span',{style:(item.lang == 'default' ? 'margin:0' : '')},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('span',{domProps:{"innerHTML":_vm._s('&nbsp;')}})]:_vm._e(),(item.text.indexOf('_') > -1)?[_c('draggable',{staticClass:"dragArea",attrs:{"list":_vm.greFillSelected[item.count],"ghost-class":"ghost","group":"people","filter":'.fill-text'}},[_c('span',{staticClass:"fill-text",class:[
                  _vm.greFillSelected[item.count][0] ===
                  _vm.quesAnswerList[item.count]
                    ? 'greed'
                    : 'red'
                ]},[_vm._v(_vm._s((_vm.greFillSelected[item.count][0] || {}).text)+" "),(_vm.greFillSelected[item.count][0])?_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){return _vm.clear(item.count)}}}):_vm._e()])])]:_vm._e()],2),(item.text.indexOf('_') > -1)?_c('span',{staticClass:"right-word"},[_vm._v("("+_vm._s(_vm.quesAnswerList[item.count])+")")]):_vm._e(),(/[\n\r]/g.test(item.text))?_c('br'):_vm._e()]})],2),_c('draggable',{staticClass:"fillList",attrs:{"list":_vm.greFillOption,"group":{ name: 'people', pull: 'clone', put: false },"filter":'.disabled'},on:{"end":_vm.end,"start":_vm.choose}},_vm._l((_vm.greFillResultOption),function(items,index){return _c('div',{key:items.text,staticClass:"fill-item",class:{
          disabled:
            _vm.selectDraggableIndex >= 0 && index !== _vm.selectDraggableIndex
        }},[(!_vm.fillSelected.find((m) => m && m.text === items.text))?_c('span',{staticClass:"draggable-item"},[_vm._v(" "+_vm._s(items.text)+" ")]):_vm._e()])}),0)],1),_c('Score',{attrs:{"info":_vm.examInfo,"detail":_vm.detail}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }